import styled from 'styled-components';
import background  from '../../assets/images/backgroundBuy.svg'

export const Container = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    height: 10vh;
    
    left: 0px;
    top: 0px;
    z-index: 100;
    
    background: var(--vine);
    border-radius: 0px 0px 20px 20px;
    
    .header-logo {
        display: flex;
        align-items: center;
        padding: 0 10px;
        width: 10vw;
        img {
            width: 7vw;
            height: 10vh;
        }
        
    }
    @media(max-width: 768px) {
        justify-content: center;
        > svg {
            color: var(--white);
            font-size: 2.5vw;
            width: 15vw;
            height: 5vh;
            position: absolute;
            right: 10px;
        }
        .header-logo {
            padding: 0;
            width: 100%;
            justify-content: center;
            img {
                width: 90%;
                height: 8vh;
            }
        }
    }
    
    
    .header-options{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 60vw;
        
        .header-option {
            display: flex;
            align-items: center;
            justify-content: center;
            
            margin: 0 10px;
            a {
                color: var(--white);
                font-size: 1.2rem;
                text-decoration: none;
                font-weight: bold;
                transition: opacity 0.2s;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
    .header-icons{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 20vw;
        max-width: 300px;
        a {
            color: var(--white);
            
            svg {
                width: 2rem;
                height: 2rem;
            }
            :hover {
                opacity: 0.7;
            }
        }
        
    }
    `;


export const ModalView = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100%;
    color: white;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 10vh;

    div {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        header{
            top:0;
            width: 100vw;
            height: 10vh;
            background-color:var(--vine) ;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            h1 {
                font-size: 2rem;
                font-weight: bold;    
            }
        }
        

        span {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            overflow: auto;
            height: 70vh;
            ::-webkit-scrollbar {
                width: 12px;               /* width of the entire scrollbar */
            }

            ::-webkit-scrollbar-track {
            background: var(--vine);        /* color of the tracking area */
            }

            ::-webkit-scrollbar-thumb {
            background-color: var(--yellow);    /* color of the scroll thumb */
            border-radius: 20px;       /* roundness of the scroll thumb */
            border: 3px solid var(--brown);  /* creates padding around scroll thumb */
            }
            div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin-bottom: 2rem;   
            }
        }
        button {
                background-color: var(--white);
                color: var(--black);
                border: none;
                border-radius: 20px;
                padding: 1rem 2rem;
                font-size: 1.3rem;
                font-weight: bold;
                margin-top: 2rem;
        }
    }
`
export const ModalViewMobile = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 10vh;

    header{
        top:0;
        width: 100vw;
        height: 10vh;
        background-color:var(--vine) ;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    div {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
            font-size: 2.5rem;
            font-weight: bold;

        }

        span {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin-bottom: 2rem;   
            }
        }
        button {
                background-color: var(--black);
                color: var(--white);
                border: none;
                border-radius: 20px;
                padding: 1rem 2rem;
                font-size: 1.3rem;
                font-weight: bold;
                margin-top: 2rem;
        }
    }
`
export const MobileMenu = styled.div`
    background-color: var(--brown);

    z-index: 999;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 80vw;
    height: 110vh;
    box-shadow:  0px 0px 0px 20vw rgba(50, 50, 50, 0.15);  

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > svg  {
            color: var(--white);
            width: 10vw;
            height: auto;
            position: absolute;
            right: 0;
            top: 0;
    }
    .header-options{
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        width: 100%;
        height: 40%;
        .header-option {
            display: flex;
            align-items: center;
            justify-content: center;
            
            > a {
                color: var(--white);
                font-size: 1.3rem;
                text-decoration: none;
                font-weight: bold;
                transition: opacity 0.2s;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
    .header-icons{
        display: flex;
        align-items: center;
        justify-content: center;

        width: 80%;
        height: 30%;
        .header-icon{
            width: 15%;
            height: 15%;
            a {
                
                color: var(--white);
                
                svg {
                    width: 100%;
                    height: 100%;
                }
                :hover {
                    opacity: 0.7;
                }
            }
        }
        
    }
`;



