import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { FiMenu, FiX } from 'react-icons/fi';
import Modal from 'react-modal';


import { Container, MobileMenu, ModalView, ModalViewMobile } from './styles';
import background from '../../assets/images/backgroundBuy.svg'
import logo from '../../assets/images/rigoniLogo.svg';

//create a header with 4 options and 3 icons of facebook, instagram and whatsapp(react icons)
export function Header() {
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [modalOpenBuy, setModalOpenBuy] = useState<boolean>(false);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const openModalBuy = () => {
        setModalOpenBuy(true);
    }

    const closeModalBuy = () => {
        setModalOpenBuy(false);
    }
    const customStylesBuy = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%',
            zIndex: '999',

        },
        overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.40)',
            zIndex: 100,
        }
    };


    let isMobile: boolean = (width <= 768);

    if (isMobile) {
        return (
            <Container>
                <Modal
                    isOpen={modalOpenBuy}
                    onRequestClose={closeModalBuy}
                    style={customStylesBuy}
                >
                    <ModalViewMobile >
                        <div>
                            <header>
                                <h1>Onde Comprar</h1>
                            </header>
                            <span>
                                <div>
                                    <h2>Laticíneos Rigoni</h2>
                                    <p>Estrada Andradas - Cocais - Km. 5, S/N - Sitio Santo Antonio;
                                        Cocais, Andradas - MG - 37795-000.
                                    </p>
                                </div>
                                <div>
                                    <h2>Casa de Queijos Rigoni</h2>
                                    <p>
                                        Av. Ricarti Teixeira, 270 - Vila Souza, Andradas - MG - 37795-000
                                    </p>
                                </div>
                                <div>
                                    <h2>Oba Supermercados</h2>
                                </div>
                                {/* 
                            Serra Azul Supermercados
                            UniForte Supermercados
                            Big Bom Supermercados */}
                                <div>
                                    <h2> Serra Azul Supermercados</h2>
                                    <p></p>
                                </div>
                                <div>
                                    <h2>UniForte Supermercados</h2>
                                    <p></p>
                                </div>
                                <div>
                                    <h2>Big Bom Supermercados</h2>
                                    <p></p>
                                </div>
                            </span>
                            <button onClick={closeModalBuy}>
                                Voltar
                            </button>
                        </div>

                    </ModalViewMobile>
                </Modal>
                <div className="header-logo">
                    <a href="#home">
                        <img src={logo} alt="logo" />
                    </a>
                </div>
                <FiMenu onClick={() => { setShowMenu(!showMenu) }}></FiMenu>
                {showMenu &&
                    <>
                        <MobileMenu>
                            <FiX onClick={() => { setShowMenu(!showMenu) }} ></FiX>
                            <div className="header-options">
                                <div className="header-option">
                                    <a href="#about">
                                        <p>Quem Somos</p>
                                    </a>
                                </div>
                                <div className="header-option">
                                    <a href="#products">
                                        <p>Nossos Produtos</p>
                                    </a>
                                </div>
                                <div className="header-option" onClick={() => { setModalOpenBuy(!modalOpenBuy) }} >
                                    <a >
                                        <p>Onde Comprar</p>
                                    </a>
                                </div>
                                <div className="header-option" >
                                    <a href="#footer">
                                        <p>Contato</p>
                                    </a>
                                </div>
                            </div>
                            <div className="header-icons">
                                <div className="header-icon">
                                    <a href="https://www.facebook.com/queijosrigonioficial" target="_blank" rel="noopener noreferrer">
                                        <FaFacebook />
                                    </a>
                                </div>
                                <div className="header-icon">
                                    <a href="https://www.instagram.com/casadequeijos_rigoni/" target="_blank" rel="noopener noreferrer">
                                        <FaInstagram />
                                    </a>
                                </div>
                                <div className="header-icon">
                                    <a href="https://api.whatsapp.com/send?phone=5535984395531" target="_blank" rel="noopener noreferrer">
                                        <FaWhatsapp />
                                    </a>
                                </div>
                            </div>
                        </MobileMenu>
                        <span onClick={() => { setShowMenu(!showMenu) }} style={{ position: 'fixed', top: '0', left: '0', backgroundColor: '#77777760', width: '50vw', height: '110vh', zIndex: 998 }}>
                        </span>



                    </>
                }
            </Container>
        )
    }
    return (
        <Container>
            <Modal
                isOpen={modalOpenBuy}
                onRequestClose={closeModalBuy}
                style={customStylesBuy}
            >
                <ModalView >
                    <div>
                        <header>

                            <h1>Onde Comprar</h1>
                        </header>
                        <span>
                            <div>
                                <h2>Laticíneos Rigoni</h2>
                                <p>Estrada Andradas - Cocais - Km. 5, S/N - Sitio Santo Antonio;
                                    Cocais, Andradas - MG - 37795-000.
                                </p>
                            </div>
                            <div>
                                <h2>Casa de Queijos Rigoni</h2>
                                <p>
                                    Av. Ricarti Teixeira, 270 - Vila Souza, Andradas - MG - 37795-000
                                </p>
                            </div>
                            <div>
                                <h2>Oba Supermercados</h2>
                            </div>
                            {/* 
                            Serra Azul Supermercados
                            UniForte Supermercados
                            Big Bom Supermercados */}
                            <div>
                                <h2> Serra Azul Supermercados</h2>
                                <p></p>
                            </div>
                            <div>
                                <h2>UniForte Supermercados</h2>
                                <p></p>
                            </div>
                            <div>
                                <h2>Big Bom Supermercados</h2>
                                <p></p>
                            </div>
                        </span>
                        <button onClick={closeModalBuy}>
                            Voltar
                        </button>
                    </div>

                </ModalView>
            </Modal>
            <div className="header-logo" >
                <a href="#home">
                    <img src={logo} alt="logo" />
                </a>
            </div>
            <div className="header-options">
                <div className="header-option">
                    <a href="#about">
                        <p>Quem Somos</p>
                    </a>
                </div>
                <div className="header-option">
                    <a href="#products">
                        <p>Nossos Produtos</p>
                    </a>
                </div>
                <div className="header-option" onClick={() => { setModalOpenBuy(!modalOpenBuy) }}>
                    <a >
                        <p>Onde Comprar</p>
                    </a>
                </div>
                <div className="header-option">
                    <a href="#footer">
                        <p>Contato</p>
                    </a>
                </div>
            </div>
            <div className="header-icons">
                <div className="header-icon">
                    <a href="https://www.facebook.com/queijosrigoni" target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                    </a>
                </div>
                <div className="header-icon">
                    <a href="https://www.instagram.com/casadequeijos_rigoni/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram />
                    </a>
                </div>
                <div className="header-icon">
                    <a href="https://api.whatsapp.com/send?phone=5535984395531" target="_blank" rel="noopener noreferrer">
                        <FaWhatsapp />
                    </a>
                </div>
            </div>
        </Container>
    )
}