import { Header } from "./components/Header";
import { Home } from "./pages/home";
import { GlobalStyles } from "./styles/global";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function App() {
  return (
    <div className="App">
      <Header></Header>
      <Home></Home>
      <GlobalStyles />
    </div>
  );
}
