import { Header } from "../../components/Header";
import { ContainerFirst, ContainerSecond, Card, ContainerThird, ContainerFourth, ContainerFifth, Footer, FooterBlack } from "./styles";

import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';



import Slider from "react-slick";
import Modal from 'react-modal';

import ricota from "../../assets/images/queijos/ricota.svg";
import minasPadrao from "../../assets/images/queijos/minas.svg";
import cabacinha from "../../assets/images/queijos/cabacinha.svg";
import prato from "../../assets/images/queijos/prato.svg";
import frescal from "../../assets/images/queijos/frescal.svg";
import nozinho from "../../assets/images/queijos/nozinho.svg";
import coalho from "../../assets/images/queijos/coalho.svg";
import palito from "../../assets/images/queijos/palito.svg";
import meiaCura from "../../assets/images/queijos/meiaCura.svg";

import ricotaTabela from "../../assets/images/queijos/tabelaNutricional/ricota.svg";
import minasPadraoTabela from "../../assets/images/queijos/tabelaNutricional/minas.svg";
import cabacinhaTabela from "../../assets/images/queijos/tabelaNutricional/cabacinha.svg";
import pratoTabela from "../../assets/images/queijos/tabelaNutricional/prato.svg";
import pratoLancheTabela from "../../assets/images/queijos/tabelaNutricional/pratoLanche.svg";
import frescalTabela from "../../assets/images/queijos/tabelaNutricional/frescal.svg";
import nozinhoTabela from "../../assets/images/queijos/tabelaNutricional/nozinho.svg";
import coalhoTabela from "../../assets/images/queijos/tabelaNutricional/coalho.svg";
import palitoTabela from "../../assets/images/queijos/tabelaNutricional/palito.svg";
import meiaCuraTabela from "../../assets/images/queijos/tabelaNutricional/meiaCura.svg";


import fourthTableChesse from "../../assets/images/fourthTableChesse.svg";
import fifthTableChesse from "../../assets/images/fifthTableChesse.svg";
// import videoInstitucional from "../../assets/institucional.mp4";

import img1 from "../../assets/images/img1.svg";
import img2 from "../../assets/images/img2.svg";
import img3 from "../../assets/images/img3.svg";

import paoQueijo from "../../assets/images/receitas/paoQueijo.svg";
import bolinhaQueijo from "../../assets/images/receitas/bolinhaQueijo.png";
import rigoniLogo from "../../assets/images/rigoniLogo.svg";
import { useEffect, useState } from "react";

type chesseType = "ricota" | "minasPadrao" | "cabacinha" | "prato" | "frescal" | "nozinho" | "coalho" | "palito" | "meiaCura" | "pratoLanche";

export function Home() {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalOpenBuy, setModalOpenBuy] = useState<boolean>(false);
    const [chesseSelect, setChesseSelect] = useState<chesseType>("ricota");
    const [width, setWidth] = useState<number>(window.innerWidth);

    const date = new Date();

    let year = date.getFullYear();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    let isMobile: boolean = (width <= 768);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const settingsMain = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        fade: true,
        arrows: false,
        pauseOnHover: false,
    };



    const openModal = (type: chesseType) => {
        setModalOpen(true);
        setChesseSelect(type);
    }

    const closeModal = () => {
        setModalOpen(false);
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            margin: 0,
            padding: 0,
            background: 'transparent',
            border: 0,

        },
        overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.40)',
            zIndex: 100,
        }
    };
    const customStylesMobile = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            margin: 0,
            padding: 0,
            background: 'transparent',
            border: 0,
            width: '95vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

        },
        overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.40)',
            zIndex: 100,
        }
    };

    //   const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 5000,

    //   };

    return (
        <>
            {/* <ContainerFirst>
            <h1>Conheça todas <br /> as nossas delícias!</h1>
        </ContainerFirst> */}

            <ContainerFirst id="home">
                <Slider {...settingsMain}>
                    <div className="sliderFirst">
                        <h1>Conheça todas <br /> as nossas delícias!</h1>
                    </div>
                    <div className="sliderSecond">
                        <span>
                            <p>
                                GARANTIA DE <br /> QUALIDADE
                            </p>
                        </span>
                    </div>
                    <div className="sliderThird">

                        <span>
                            <p>
                                MONTE A SUA TÁBUA DE FRIOS <br /> COM NOSSOS PRODUTOS!
                            </p>
                        </span>
                    </div>
                </Slider>
            </ContainerFirst>
            <ContainerSecond>
                <Slider {...settings}>
                    <Card>
                        <h2>Receita de Pão de Queijo</h2>
                        <section>
                            <img src={paoQueijo} alt="" />
                            <span>
                                <h3>Ingredientes:</h3>
                                <p>
                                    800 g de polvilho azedo;<br />
                                    1 xícara de água;<br />
                                    1 xícara de leite;<br />
                                    1/2 xícara de óleo;<br />
                                    2 ovos;<br />
                                    100 g de queijo ralado;<br />
                                    Sal a gosto.<br />
                                </p>
                            </span>
                        </section>
                    </Card>
                    <Card>
                        <h2>Modo de preparo</h2>
                        <section className="fullText">
                            {/* <img src="https://s2.glbimg.com/9QNO2q8PEeOPLWikHCfMzZl75ZE=/620x466/e.glbimg.com/og/ed/f/original/2020/08/15/pao-de-queijo-mineiro.jpg" alt=""/> */}
                            <span>
                                {/* <h3>Ingredientes:</h3> */}
                                <p>
                                    Em uma panela, ferva a água e acrescente o leite, o óleo e o sal.
                                    Adicione o polvilho aos poucos até dar liga. Pode ser que você não precise usar os 800g, então coloque devagar e sove a massa até soltar da mão: esse é o ponto.
                                    Quando a massa estiver morna, acrescente o queijo parmesão, os ovos e misture bem.
                                    Unte as mãos e enrole bolinhas de 2 cm de diâmetro.
                                    Disponha as bolinhas em uma assadeira untada com óleo, deixando um espaço entre elas.
                                    Asse em forno médio (180º C), preaquecido, por cerca de 40 minutos.
                                </p>
                            </span>
                        </section>
                    </Card>
                    <Card>
                        <h2>Receita de Bolinha de Queijo</h2>
                        <section>
                            <img src={bolinhaQueijo} alt="" />
                            <span>
                                <h3>Ingredientes:</h3>
                                <p>
                                    1 xícara (chá) de leite;<br />
                                    1 xícara (chá) de farinha de trigo;<br />
                                    1 colher (sopa) de margarina;<br />
                                    1 gema;<br />
                                    1 pitada de sal;<br />
                                    Queijo a gosto;<br />
                                    Oleo para fritar.<br />
                                </p>
                            </span>
                        </section>
                    </Card>
                    <Card>
                        <h2>Modo de preparo</h2>
                        <section className="fullText">
                            {/* <img src="https://s2.glbimg.com/9QNO2q8PEeOPLWikHCfMzZl75ZE=/620x466/e.glbimg.com/og/ed/f/original/2020/08/15/pao-de-queijo-mineiro.jpg" alt=""/> */}
                            <span>
                                {/* <h3>Ingredientes:</h3> */}
                                <p>
                                    Em uma panela, adicione o leite, a farinha de trigo, a margarina, a gema e o sal.
                                    Leve ao fogo e mexa com um garfo até que a massa solte da panela, depois deixe esfriar.
                                    Modele a massa em bolinhas e recheie com o queijo, depois passe o bolinho na gema de ovo e na farinha de rosca.
                                    Em uma panela, adicione o óleo, depois de quente adicione as bolinhas e frite-as.
                                </p>
                            </span>
                        </section>
                    </Card>

                </Slider>
            </ContainerSecond>
            <ContainerThird id="products">
                <Slider {...settings}>
                    <Card>
                        <section>
                            <span>
                                <h3>Ricota:</h3>
                                <p>
                                    A Ricota Fresca Rigoni se sobressai pelo seu sabor
                                    único e pelo seu baixo teor de gordura. É ideal para sua
                                    dieta, e saboroso quando adicionado de especiarias e
                                    ervas. Não podemos esquecer dos patês e cremes
                                    que combinam com pães e torradas do seu café da
                                    manhã.
                                </p>
                                <button onClick={() => { openModal('ricota') }}>
                                    Saiba mais!
                                </button>
                            </span>
                            <img src={ricota} alt="" />

                        </section>


                    </Card>
                    <Card>
                        <section>
                            <span>
                                <h3>Queijo Mussarela Cabacinha</h3>
                                <p>
                                    De origem italiana esse queijo ficou famoso pelo formato
                                    charmoso que tem. Os curiosos se surpreendem com o sabor tradicional de um típico queijo mineiro. Quem não
                                    conhece experimenta e quem já comeu tem sempre um queijo cabacinha em casa!
                                </p>

                                <button onClick={() => { openModal('cabacinha') }}>
                                    Saiba mais!
                                </button>
                            </span>
                            <img src={cabacinha} alt="" />
                        </section>
                    </Card>
                    <Card>
                        <section>
                            <span>
                                <h3>Queijo Minas Padrão:</h3>
                                <p>
                                    Produzido há duas décadas, o Queijo Minas Padrão
                                    Rigoni vem conquistando paladares com seu sabor
                                    pronunciado e um gostinho tradicional que nunca
                                    se perde. Com variadas formas de ser degustado,
                                    como em porções, tostados e combinados com
                                    diversos tipos de doces.
                                </p>
                                <button onClick={() => { openModal('minasPadrao') }}>
                                    Saiba mais!
                                </button>
                            </span>
                            <img src={minasPadrao} alt="" />
                        </section>
                    </Card>
                    <Card>
                        <section>
                            <span>
                                <h3>Queijo Minas Meia Cura:</h3>
                                <p>
                                    O marcante Queijo Minas Meia Cura Rigoni, de
                                    textura macia e suave, combina perfeitamente com aquele
                                    cafezinho ou doces caseiros. Pode ser usado também no preparo
                                    do tradicional pão de queijo mineiro e em variadas receitas deliciosas.
                                    É ótimo para gratinar, fazer um saboroso fondue ou em porções na sua forma tradicional.
                                </p>
                                <button onClick={() => { openModal('meiaCura') }}>
                                    Saiba mais!
                                </button>
                            </span>
                            <img src={meiaCura} alt="" />
                        </section>
                    </Card>
                    <Card>
                        <section>
                            <span>
                                <h3>Queijo Mussarela Palito:</h3>
                                <p>
                                    Um dos queridinho de Minas Gerais que está presente
                                    em muitas ocasiões. O palitinho de mussarela é um
                                    petisco completo que sempre faz muito sucesso entre os
                                    apaixonados por queijo. Tradicional ou temperado
                                    sempre vai ser indispensável nas reuniões com a família e
                                    os amigos.
                                </p>
                                <button onClick={() => { openModal('palito') }}>
                                    Saiba mais!
                                </button>

                            </span>
                            <img src={palito} alt="" />
                        </section>
                    </Card>
                    <Card>
                        <section>
                            <span>
                                <h3>Queijo de Coalho:</h3>
                                <p>
                                    O Queijo Coalho Rigoni possui um sabor único e
                                    levemente ácido. De preparo rápido e prático, é excelente
                                    para compor aquele churrasco de fim de semana, além de ser uma ótima opção para acompanhar variados
                                    pratos, mel, geleias e caldo de cana.
                                </p>
                                <button onClick={() => { openModal('coalho') }}>
                                    Saiba mais!
                                </button>
                            </span>
                            <img src={coalho} alt="" />
                        </section>
                    </Card>
                    <Card>
                        <section>
                            <span>
                                <h3>Queijo Mussarela Nozinho:</h3>
                                <p>
                                    Um charme a mais em sua mesa para receber convidados e agradar a todas as idades. O queijo nozinho tem um sabor leve e agradável, pronto para ser consumido em qualquer momento, como um
                                    lanchinho rápido ou um delicioso acompanhamento em receitas e saladas!
                                </p>
                                <button onClick={() => { openModal('nozinho') }}>
                                    Saiba mais!
                                </button>
                            </span>
                            <img src={nozinho} alt="" />
                        </section>
                    </Card>
                    <Card>
                        <section>
                            <span>
                                <h3>Queijo Minas Frescal:</h3>
                                <p>
                                    O Queijo Minas Frescal Rigoni traz mais sabor às suas
                                    manhãs.
                                    Com um sabor inigualável, um dos queijos mais
                                    populares do Brasil, é muito indicado para quem busca
                                    uma vida mais saudável.
                                </p>
                                <button onClick={() => { openModal('frescal') }}>
                                    Saiba mais!
                                </button>

                            </span>
                            <img src={frescal} alt="" />
                        </section>
                    </Card>
                    <Card>
                        <section>
                            <span>
                                <h3>Queijo Prato:</h3>
                                <p>
                                    O queijo prato foi introduzido em Minas Gerais na
                                    década de 20 do século passado através de imigrantes dinamarqueses.
                                    Com uma textura macia que permite um fatiamento prático, o Queijo Prato Rigoni,
                                    é ideal para fazer lanches frios ou quentes. Por conta de seu derretimento irresistível deixa qualquer receita mais saborosa.
                                </p>
                                <button onClick={() => { openModal('prato') }}>
                                    Saiba mais!
                                </button>
                            </span>
                            <img src={prato} alt="" />
                        </section>
                    </Card>

                    <Card>
                        <section>
                            <span>
                                <h3>Queijo Prato (Lanche):</h3>
                                <p>
                                    Queijo Prato Rigoni, é ideal para fazer lanches frios ou quentes. Por conta de seu derretimento irresistível
                                    deixa qualquer receita mais saborosa. Com certeza uma excelente opção para quem gosta de praticidade na hora de
                                    preparar um lanchinho!
                                </p>
                                <button onClick={() => { openModal('prato') }}>
                                    Saiba mais!
                                </button>
                            </span>
                            <img src={prato} alt="" />
                        </section>

                        <Modal
                            isOpen={modalOpen}
                            onRequestClose={closeModal}
                            style={isMobile ? customStylesMobile : customStyles}
                        >
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

                                {chesseSelect === 'ricota' &&
                                    <img src={ricotaTabela} alt="" style={{ width: '100%', height: '100%' }} />
                                }
                                {chesseSelect === 'minasPadrao' &&
                                    <img src={minasPadraoTabela} alt="" style={{ width: '100%', height: '100%' }} />
                                }
                                {chesseSelect === 'cabacinha' &&
                                    <img src={cabacinhaTabela} alt="" style={{ width: '100%', height: '100%' }} />
                                }
                                {chesseSelect === 'prato' &&
                                    <img src={pratoTabela} alt="" style={{ width: '100%', height: '100%' }} />
                                }
                                {chesseSelect === 'pratoLanche' &&
                                    <img src={pratoLancheTabela} alt="" style={{ width: '100%', height: '100%' }} />
                                }
                                {chesseSelect === 'frescal' &&
                                    <img src={frescalTabela} alt="" style={{ width: '100%', height: '100%' }} />
                                }
                                {chesseSelect === 'nozinho' &&
                                    <img src={nozinhoTabela} alt="" style={{ width: '100%', height: '100%' }} />
                                }
                                {chesseSelect === 'coalho' &&
                                    <img src={coalhoTabela} alt="" style={{ width: '100%', height: '100%' }} />
                                }
                                {chesseSelect === 'palito' &&
                                    <img src={palitoTabela} alt="" style={{ width: '100%', height: '100%' }} />
                                }
                                {chesseSelect === 'meiaCura' &&
                                    <img src={meiaCuraTabela} alt="" style={{ width: '100%', height: '100%' }} />
                                }
                            </div>

                        </Modal>
                    </Card>

                </Slider>
            </ContainerThird>
            <ContainerFourth>
                <span>
                    <h1>Tradição e <br />amor à mesa!</h1>
                    <section>
                        <p>
                            Foi pelas mãos do Sr. Antônio Marco Rigoni e da Sra. Daura Barbosa Rigoni que em maio de 1995 foi
                            inaugurada a pedra fundamental da Indústria de Laticínios Rigoni, em Andradas, Sul de Minas Gerais. Em dezembro de 2014, a Rigoni expandiu sua produção com a adesão de uma nova unidade e a aquisição de equipamentos modernos de alta tecnologia para a fabricação de queijos. A qualidade, o amor e a dedicação são a matéria prima do sucesso dos produtos Rigoni. Esses valores garantem a construção da tradição de uma empresa preocupada em oferecer os melhores e
                            mais saborosos produtos para seus consumidores.
                        </p>
                        {/* <button >Conheça a Rigoni</button>  oncli go to #video react code*/}
                        <button onClick={() => { window.location.href = '#video' }}>Conheça a Rigoni</button>
                    </section>
                </span>
                <img src={fourthTableChesse} alt="" />
            </ContainerFourth>
            <ContainerFifth>
                <span id="about">
                    <h1 id="video">Conheça a nossa fabrica!</h1>
                    <iframe src={"https://www.youtube.com/embed/71n0HdwO6L0"} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                </span>
                <section>
                    <h2>Acompanhe nossas redes sociais!</h2>
                    <span>
                        <a href="https://www.facebook.com/queijosrigonioficial" target="_blank" rel="noopener noreferrer">
                            <FaFacebook />
                        </a>
                        <a href="https://www.instagram.com/queijosrigonioficial/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </a>
                        <p>@queijosrigoni</p>
                    </span>
                    <div>
                        <img src={img1} alt="" />
                        <img src={img2} alt="" />
                        <img src={img3} alt="" />
                    </div>
                </section>

                <img src={fifthTableChesse} alt="" />
            </ContainerFifth>
            <Footer>
                <div id="footer">
                    <p>Localização: Estrada Andradas/São João da Boa Vista, Km 5 - Cocais - 37795-000 - Andradas/MG</p>
                    <p>Telefone de Contato: (35) 3590-2291.</p>
                    <p>Horário de Funcionamento:
                        De segunda à sexta-feita das 07h às 17h.</p>
                </div>
                <div>
                    <img src={rigoniLogo} alt="" />
                </div>

            </Footer>
            <FooterBlack>
                <span>
                    {isMobile ?
                        <p>
                            © {year} Todos os direitos reservados. <br /> By Queijos Rigoni
                        </p>
                        :
                        <p>
                            © {year} Todos os direitos reservados. By Queijos Rigoni
                        </p>
                    }
                </span>
            </FooterBlack>


        </>
    )
}