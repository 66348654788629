import styled from 'styled-components';
import background from '../../assets/images/backgroundQueijos.svg';

import sliderSecond from '../../assets/images/sliderSecond.svg';
import sliderThird from '../../assets/images/sliderThird.svg';
import backgroundFourth from '../../assets/images/backgroundFourth.svg';
import backgroundChesses from '../../assets/images/backgroundChesses.svg';
import backgroundWood from '../../assets/images/woodBackground.svg';


// export const ContainerFirst = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;

//     width: 100vw;
//     height: 100vh;
//     background-color: white;

//     background: no-repeat;
//     background-size: cover;
//     background-image: url(${background});
//     h1 {
//         font-size: 8rem;
//         color: var(--white);
//         font-family: 'Alex Brush', cursive;
//         font-weight: 300;
//         text-align: center;
        
//     }
// `;

export const ContainerFirst = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;
    background-color: white;
    .slick-slider{
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        > button {
            width: 4.2rem;
        }
    }
    
    /* .slick-prev:before, .slick-next:before { 
        display: none;
    } */


 .sliderFirst {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: no-repeat;
    background-size: cover;
    background-image: url(${background});
    
        h1 {
            margin: 20vh;
            font-size: 8rem;
            color: var(--white);
            font-family: 'Alex Brush', cursive;
            font-weight: 300;
            text-align: center;
        
        }
        @media(max-width: 768px){
            h1 {
                margin: 10vh;
                font-size: 4rem;
            }
        }
}
 .sliderSecond {
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    background: no-repeat;
    background-size: cover;
    background-image: url(${sliderSecond});

    position: relative;

        span {
            background-color: var(--yellow);
            position: absolute;
            right: 0;
            top: 45vh;
            height: 15vh;
            width: 20vw;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 60px 0 0 60px;

            p {
                color: var(--white);
                font-size: 1.6rem;
                padding: 1rem;

            }
            @media(max-width: 768px){
                top: 60vh;

                width: 50vw;
                p {
                    font-size: 1.2rem;
                }
            
            }
        }

    }

 .sliderThird {
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    background: no-repeat;
    background-size: cover;
    background-image: url(${sliderThird});

    position: relative;

        span {
            background-color: var(--red);
            position: absolute;
            left: 0;
            top: 65vh;
            height: 12vh;
            width: 35vw;
            max-width: 400px;

            display: flex;
            align-items: center;
            justify-content: center;

            border-radius:  0 60px 60px 0;

            p {
                color: var(--white);
                font-size: 1.3rem;
                padding: 1rem;

            }
            @media(max-width: 768px){
                top: 70vh;

                width: 50vw;
                height: 16vh;

                p {
                    font-size: 1.2rem;
                }
            
            }
        }
    }

`;


export const ContainerSecond = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    .slick-slider{
        width: 90vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        > button {
            width: 4.2rem;
        }
    }
    .slick-prev:before, .slick-next:before { 
        font-size: 4rem;
        @media(max-width: 768px){
            font-size: 2rem;
        }
    }
    /* .slick-list {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    } */

    .slick-dots li.slick-active button:before {
    color: #00000090;
    }

    .slick-prev:before,
    .slick-next:before {
    color: #00000090;
    }
   
    /* h3 {
        font-size: 8rem;
        color: var(--black);
        text-align: center;
        
    } */
`;

export const Card =  styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.5rem;
        text-align: center;
        padding: 2.5rem 0;
        @media(max-width: 768px){
            font-size: 1.5rem;
        }
    }
    button {
                background-color: var(--black);
                color: var(--white);
                border: none;
                border-radius: 20px;
                padding: 1rem 2rem;
                font-size: 1.3rem;
                font-weight: bold;
                margin-top: 2rem;
    }
    section {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        padding: 2.5rem 0;

        img {
            width: 30%;
            height: auto;
            border-radius: 20px;
        }
        span {
            padding: 0 20px;
            width: 25%;
            padding-top: 1rem;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            h3{
                font-size: 2rem;
            }
        }
        @media(max-width: 768px){
            padding: 0;
            flex-direction: column;
            align-items: center;
            img {
                width: 70%;
            }
            span {
                width: 100%;
                padding: 0;
                align-items: center;
                p {
                    padding: 10px;
                }
            }

        }
    }

    .fullText {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        padding: 2.5rem 0;
        span {
            padding: 0 20px;
            width: 60%;
            padding-top: 1rem;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            h3{
                font-size: 2rem;
            }
        }
        @media(max-width: 768px){
            padding: 0;
            flex-direction: column;
            align-items: center;
            img {
                width: 70%;
            }
            span {
                width: 100%;
                padding: 0;
                align-items: center;
                p {
                    padding: 10px;
                }
            }

        }
    }

`
export const ContainerThird = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    background: no-repeat;
    background-size: cover;
    background-image: url(${backgroundChesses});

    .slick-slider{
        width: 90vw;
        height: 90vh;
        display: flex;
        align-items: center;
        justify-content: center;
        > button {
            width: 4.2rem;
        }
    }
    .slick-prev:before, .slick-next:before { 
        font-size: 4rem;
    }
    /* .slick-list {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    } */

    .slick-dots li.slick-active button:before {
    color: #00000090;
    }

    .slick-prev:before,
    .slick-next:before {
    color: #00000090;
    }
   
    h3 {
        font-size: 8rem;
        color: var(--black);
        text-align: center;
        
    }

`
export const ContainerFourth = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 110vh;

    position: relative;

    background-color: var(--black);

    background: no-repeat;
    background-size: cover;
    background-image: url(${backgroundFourth});
    @media(max-width: 768px){
        height: 150vh;

    }
    span {
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
        width: 100vw;
        height: 110vh;
        @media(max-width: 768px){
            height: 150vh;

        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            font-size: 8rem;
            color: var(--white);
            font-family: 'Alex Brush', cursive;
            font-weight: 300;
            text-align: center;
            @media(max-width: 768px){
                font-size: 4rem;
            }
        }
        section {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
    
            width: 60%;
    
            p {
                font-size: 1.3rem;
                color: var(--white);
            }
            button {
                background-color: var(--white);
                color: var(--black);
                border: none;
                border-radius: 20px;
                padding: 1rem 2rem;
                font-size: 1.3rem;
                font-weight: bold;
                margin-top: 2rem;
            }
        }
    }
    img {
        width: 20%;
        height: auto;
        position: absolute;
        bottom: -200px;
        right: 0;
        z-index: 10;
        @media(max-width: 768px){
            width: 50%;
            bottom: -190px;
        }
    }
`;

export const ContainerFifth = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 100vw;
    height: 170vh;
    position: relative;
    @media(max-width: 768px){
        height: auto;
    }

    /* background-color: var(--black);
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.7651435574229692) 62%, rgba(0,0,0,0.5970763305322129) 100%); */

    background: no-repeat;
    background-size: cover;
    background-image: url(${backgroundWood});

    > span {
        width: 100vw;
        height: 100vh;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media(max-width: 768px){
            height: 90vh;
        }
        h1 {
            font-size: 2.5rem;
            color: var(--white);
            font-weight: 300;
            text-align: center;
            padding: 2.5rem 0;
            @media(max-width: 768px){
                font-size: 1.5rem;
            }
        }
        iframe{
            width: 60%;
            height: 60%;
            @media(max-width: 768px){
                width: 90%;
                height: 40%;
            }
        }
     }
     section {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        width: 100vw;
        height: 70vh;
        @media(max-width: 768px){
            height: auto;
        }

        h2 {
            font-size: 2rem;
            color: var(--white);
            @media(max-width: 768px){
                font-size: 1.3rem;
                margin-bottom: 2rem;
            }
        }
        > span {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 20%;
            height: 15%;

            a {
                color: var(--white);
                svg {
                    width: 2rem;
                    height: 2rem;
                }
                :hover {
                    opacity: 0.7;
                }
            }
            p {
                font-size: 1.5rem;
                color: var(--white);
            }
            @media(max-width: 768px){
                width: 80%;
            }
        }

        div {
            width: 80%;
            height: 75%;

            display: flex;
            align-items: center;
            justify-content: space-around;

            img {
                width: 20%;
            }
            @media(max-width: 768px){
                flex-direction: column;
                width: 90%;
                img {
                    width: 80%;
                    margin: 2rem 0;
                }  
            }
        }

     }



    > img {
        width: 20%;
        height: auto;
        position: absolute;
        bottom: 200px;
        left: 0;

        @media(max-width: 768px){

            display: none;
        }
    }
`;


export const Footer = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 40vh;

    background-color: var(--yellow);

    div {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 40%;
        padding: 0 20px;
        p {
            font-size: 1.2rem;
            color: var(--white);
            text-align: left;
            margin-bottom: 15px;
        }
        img {
            width: 60%;
        }
    }
    span{
        display: flex;
        align-items: center;
        justify-content: center;
        
        background-color: var(--black);
        width: 100vw;
        height: 10vh;
        color: var(--white);
    }
    @media(max-width: 768px){
        height: auto;
        flex-direction: column;


        div {
        align-items: center;
            padding-top: 1rem;
            padding-bottom: 3rem;

            width: 100%;
            p {
                font-size: 1.2rem;
            text-align: left;
            width: 100%;

            }
            img {
                padding-top: 1rem;
                width: 60%;
            }
        }
    }
`;
export const FooterBlack = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        
        background-color: var(--black);
        width: 100vw;
        height: 10vh;
        color: var(--white);
        p {
            /* break line */
            text-overflow: center ;
        }
    }
    @media(max-width: 768px){
        height: auto;
        flex-direction: column;


        div {
        align-items: center;
            padding-top: 1rem;
            padding-bottom: 3rem;

            width: 100%;
            p {
                font-size: 1.2rem;
            text-align: left;
            width: 100%;

            }
            img {
                padding-top: 1rem;
                width: 60%;
            }
        }
    }
`;