import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    :root {
        --background: #EBEBEB;
        --brown: #C08141;
        --yellow: #FFA91A;
        --grey: #787877;
        --white:#F4F0EC;
        --red: #CF2B20;
        --black: #000000;
        --vine: #200806;

    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        @media(max-width: 1080px) {
            font-size: 93.75%; //15px
        }
        @media(max-width: 720px) {
            font-size: 87.5%; //14px
        }

    }

    body {
        background: var(--background);
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
        ::-webkit-scrollbar {
                width: 12px;               /* width of the entire scrollbar */
            }

            ::-webkit-scrollbar-track {
            background: var(--vine);        /* color of the tracking area */
            }

            ::-webkit-scrollbar-thumb {
            background-color: var(--yellow);    /* color of the scroll thumb */
            border-radius: 20px;       /* roundness of the scroll thumb */
            border: 3px solid var(--brown);  /* creates padding around scroll thumb */
            }

    }
    body, input, button, textarea {
        font-family: 'Merriweather', serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 700;
    }

    button {
        cursor: pointer;
    }
    [disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
`
